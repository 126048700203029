import React from 'react';
import './PopularCategories.css'; // assuming you have a CSS file for styling

function PopularCategories() {
  return (
    <div className="popularCategories ">
      <h3>Popular Categories</h3>
      <div className="grid-container">
        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=womens_clothing%3Ejeans">
            <img src="/static/popular-categories/Categories_Jeans.jpg" alt="Jeans" />
          </a>
        </div>
        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=womens_clothing%3Edress">
            <img src="/static/popular-categories/Categories_Dresses.jpg" alt="Dresses" />
          </a>
        </div>
        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=womens_shoes">
            <img src="/static/popular-categories/Categories_Shoes.jpg" alt="Shoes" />
          </a>
        </div>
        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=men_clothing">
            <img src="/static/popular-categories/Categories_Mens.jpg" alt="Menswear" />
          </a>
        </div>

        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=womens_clothing%3Etops_tshirts">
            <img src="/static/popular-categories/Categories_Tops.jpg" alt="Tops" />
          </a>
        </div>

        <div className="popularCategories circle">
          <a href="/s?pub_categoryArray=womens_clothing%3Eskirts">
            <img src="/static/popular-categories/Categories_Skirts.jpg" alt="Skirts" />
          </a>
        </div>
      </div>
    </div>
  );
}

export { PopularCategories };
